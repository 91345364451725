var jQuery = $;

const app = {

    /** Initialize */
    init() {


    },




};

$(document).ready($.proxy(app.init, app));
